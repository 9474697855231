import { useEffect, useRef, useState, memo } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'src/hooks/useSelector';

const createMap = async (element, address, isWebView) => {
    let mapInstance = null;
    if (!process.env.SSR) {
        try {
            const showMapModule = await import(/* webpackMode: "eager" */ 'Modules/Maps/showMap');
            const result = await showMapModule.default(element, {
                address,
                disableScrollZoom: true,
                disableDrag: isWebView,
                disableDblClickZoom: isWebView,
                zoomControlPosition: {
                    left: 10,
                    top: 30,
                },
            });
            mapInstance = result.mapInstance;
        } catch (error) {
            throw error;
        }
    }
    return mapInstance;
};

const AddressWidgetMap = ({ address }) => {
    const mapContainer = useRef(null);
    const mapInstance = useRef(null);
    const webViewMode = useSelector(({ employerConstructorWebViewMode }) => employerConstructorWebViewMode);

    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        let mapData = address.mapData;
        try {
            if (typeof mapData === 'string') {
                mapData = JSON.parse(mapData);
            }
        } catch (error) {} // eslint-disable-line no-empty
        const mapDataAvailable = !!mapData?.points;
        if (mapDataAvailable) {
            createMap(mapContainer.current, { ...address, mapData }, webViewMode)
                .then((yandexMapInstance) => {
                    mapInstance.current = yandexMapInstance;
                    return null;
                })
                .catch(() => {
                    setHasError(true);
                });
        } else if (!mapDataAvailable) {
            setHasError(true);
        }
        return () => {
            mapInstance.current?.destroy?.();
        };
    }, [address, webViewMode]);

    return !hasError && <div className="widget-address-map" ref={mapContainer} />;
};

AddressWidgetMap.propTypes = {
    address: PropTypes.shape({
        mapData: PropTypes.oneOfType([
            PropTypes.shape({
                points: PropTypes.arrayOf(PropTypes.object),
            }),
            PropTypes.string,
        ]),
    }),
};

const ManualCheckChangeAddress = (prevProps, nextProps) => prevProps.address.id === nextProps.address.id;

export default memo(AddressWidgetMap, ManualCheckChangeAddress);
